import { createStore } from 'redux';

const INITIAL_MODAL_STATE = {
  // Project Modals
  createProjectVisible: false,
  createProjectInitialData: {},

  editProjectVisible: false,
  editProjectInitialData: {},

  deleteProjectVisible: false,
  deleteProjectInitialData: {},

  // Question Modals
  addQuestionVisible: false,
  addQuestionInitialData: {},

  editQuestionVisible: false,
  editQuestionInitialData: {},    

  deleteQuestionVisible: false,
  deleteQuestionInitialData: {},   

  // Answer Modals
  addAnswerVisible: false,
  addAnswerInitialData: {},

  editAnswerVisible: false,
  editAnswerInitialData: {},    

  deleteAnswerVisible: false,
  deleteAnswerInitialData: {},         

  // Level Modals
  addLevelVisible: false,
  addLevelInitialData: {},

  editLevelVisible: false,
  editLevelInitialData: {},    

  deleteLevelVisible: false,
  deleteLevelInitialData: {}, 

  // Question Category Modals
  addQuestionCategoryVisible: false,
  addQuestionCategoryInitialData: {},

  editQuestionCategoryVisible: false,
  editQuestionCategoryInitialData: {},    

  deleteQuestionCategoryVisible: false,
  deleteQuestionCategoryInitialData: {}, 

  // Category Modals
  addLevelCategoryVisible: false,
  addLevelCategoryInitialData: {},

  editLevelCategoryVisible: false,
  editLevelCategoryInitialData: {},    

  deleteLevelCategoryVisible: false,
  deleteLevelCategoryInitialData: {},   

  // Unlockable
  addUnlockableVisible: false,
  addUnlockableInitialData: {},

  editUnlockableVisible: false,
  editUnlockableInitialData: {},

  // UnlockStrategy
  addUnlockStrategyVisible: false,
  addUnlockStrategyInitialData: {},

  editUnlockStrategyVisible: false,
  editUnlockStrategyInitialData: {},

  deleteUnlockStrategyVisible: false,
  deleteUnlockStrategyInitialData: {},  

  // Template
  addTemplateVisible: false,
  addTemplateInitialData: {},

  editTemplateVisible: false,
  editTemplateInitialData: {},

  deleteTemplateVisible: false,
  deleteTemplateInitialData: {},

  // Level UnlockCondition
  editUnlockConditionVisible: false,
  editUnlockConditionInitialData: {},

  viewEntityVisible: false,
  viewEntityInitialData: {},

  callbacks: {},    
  customActions: {},
  customWarnings: {}, 
};

const ModalState = (stateIn, key, newState = 'show', data = {}, callback, customAction, customWarning) => {
  const state = Object.assign({}, INITIAL_MODAL_STATE, stateIn || {});

  // Show a specific modal, if key is given.
  if (key) {
    const completeKey = `${key}Visible`;
    const completeDataKey = `${key}InitialData`;

    if (state[completeKey] != null) {
      state[completeKey] = newState == 'show' ? true : false;
      state[completeKey] = newState == 'show' ? true : false;
    }

    // Manage the initial data of the modal window
    if (typeof state[completeDataKey] === 'object') {
      if (newState == 'show') {
        state[completeDataKey] = data;
      } else {
        state[completeDataKey] = {};
      }
    } else {
      throw new Error(`Key ${completeDataKey} does not exist`);
    }

    // Manage callback
    if (newState == 'show') {
      if (typeof callback == 'function') {
        state.callbacks[key] = callback;
      } else {
        state.callbacks[key] = null;
      }

      if (typeof customAction == 'function') {
        state.customActions[key] = customAction;
      } else {
        state.customActions[key] = null;
      }

      if (typeof customWarning == 'string') {
        state.customWarnings[key] = customWarning;
      } else {
        state.customWarnings[key] = null;
      }
    } else {
      state.callbacks[key] = null;
      state.customActions[key] = null;
      state.customWarnings[key] = null;
    }
  }

  return state;
};

const defaultSettings = () => ({
  developer_mode: false,
});

const initialState = {
  stateId: 0,
  sidebarShow: 'responsive',

  loadingApplications: true,
  loadingContent: true,

  selectedAppId: null,
  applications: [],

  selfUserId: null,
  selfUserRole: 'UNKNOWN',
  selfUserUsername: 'Username',
  selfUserImage: null,
  selfUserSettings: defaultSettings(),
  users: [],

  usersData: {},
  applicationData: {},

  modals: ModalState(),
  toasters: [],
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }

    case 'toast':
      return { 
        ...state,
        toasters: [ ...state.toasters, {
          autohide: 3000,
          fade: false,
          closeButton: true,
          title: '<title>',
          message: '<message>',
          style: 'info',
          ...rest,
        }]
      };

    case 'showModal': {
      const modalToShow = rest.id;
      const modals = ModalState(state.modals, modalToShow, 'show', rest.data, rest.callback, rest.customAction, rest.customWarning);
      const ret = { ...state, modals };
      return ret;
    }

    case 'hideModal': {
      const modalToHide = rest.id;
      const modals = ModalState(state.modals, modalToHide, 'hide', rest.data);
      const ret = { ...state, modals };
      return ret;
    }

    default:
      return state
  }
}

const store = createStore(changeState)
export default store;
