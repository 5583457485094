import { sygnet } from './sygnet'
import { logo } from './logo'
import { logoNegative } from './logo-negative'

import {
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl
} from '@coreui/icons'

import {
  cilChevronLeft,
  cilChevronRight,
  cilRectangle,
  cilHistory,
  cilHome,
  cilApplications,
  cilChartLine,
  cilSettings,
  cilBell,
  cilListRich,
  cilUser,
  cilLockLocked,
  cibAddthis,
  cilObjectGroup,
  cilLockUnlocked,
  cilMagnifyingGlass,
  cilAsterisk,
  cilPencil,
  cilTrash,
  cilCopy,
  cilBook,
  cilSave,
  cilMediaPlay,
  cilOptions,
  cilCheck,
  cilCheckAlt,
  cilXCircle,
  cilCloudUpload,
  cilGlobeAlt,

  cilNotes,
  cilText,
  cilThumbUp,

  cilImage,
  cilTags,
  cilLanguage,
  cil3d,
  cilFolder,
  cilX,
  cilArrowThickToLeft,
  cilArrowLeft,
} from '@coreui/icons'

export const icons = Object.assign({}, {
  sygnet,
  logo,
  logoNegative
}, {
  cilChevronLeft,
  cilChevronRight,
  cilRectangle,
  cilHome,
  cilChartLine,
  cilHistory,
  cilApplications,
  cilSettings,
  cilBell,
  cilListRich,
  cilUser,
  cilLockLocked,
  cibAddthis,
  cilObjectGroup,
  cilLockUnlocked,
  cilMagnifyingGlass,
  cilAsterisk,
  cilPencil,
  cilTrash,
  cilCopy,
  cilBook,
  cilSave,
  cilMediaPlay,
  cilOptions,
  cilCheck,
  cilCheckAlt,
  cilXCircle,
  cilCloudUpload,
  cilGlobeAlt,

  cilNotes,
  cilText,
  cilThumbUp,  

  cilImage,
  cilTags,  
  cilLanguage,
  cil3d,
  cilFolder,
  cilX,
  cilArrowThickToLeft,
  cilArrowLeft,
}, {
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl
})
